/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Satish",
  description: "software engineer, fintech, freelance, expert, backend",
  og: {
    title: "Satish Verma Portfolio",
    type: "website",
    url: "https://satish-verma.in/",
  },
};

//Home Page
const greeting = {
  title: "Satish Verma",
  logo_name: "SatishVerma",
  subTitleLine1:
    "A Software engineer who loves solving complex software architecture problems.",
  subTitleLine2:
    "I have expertise in Fintech (CAS/Lending/Equity funds/Reconciliation/Cash flow).",
  subTitleLine3:
    "In addition to my corporate experience, I have developed and delivered many products end to end for different Fintech/E-commerce startups.",
};
const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/satishverma110394",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://youtube.com/@satish6155",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:choudharysatish10@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "Backend Expert",
      fileName: "FullStackImg",
      skills: [
        "⚡ End to end design, development and shipping of high quality backend solutions for your Fintech/E-commerce application.",
        "⚡ Experience in working on Lending, CAS, Reconciliation and Cash flow management applications.",
        "⚡ Experience of leading tech teams consisting of Front-end developers, Backend developers, QA and Dev-Ops",
        "⚡ Experience of Data engineering using Hadoop and Scala ecosystem.",
      ],
      softwareSkills: [
        //   {
        //     skillName: "Java 8",
        //     fontAwesomeClassname: "devicon:java-wordmark",
        //     style: {
        //       color: "#F79F1B",
        //     },
        //   },
        //   {
        //     skillName: "Spring boot ecosystem",
        //     fontAwesomeClassname: "bxl:spring-boot",
        //     style: {
        //       color: "#2EC866",
        //     },
        //   },
        //   {
        //     skillName: "Micro services",
        //     fontAwesomeClassname: "carbon:microservices-1",
        //     style: {
        //       color: "#5B4638",
        //     },
        //   },
        //   {
        //     skillName: "Oracle database",
        //     fontAwesomeClassname: "devicon:oracle",
        //     style: {
        //       color: "#1F8ACB",
        //     },
        //   },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Expert in AWS services including EC2, Elastic search, RDS, SES, Area 53 etc.",
        "⚡ Experience in modern DevOps like Kubernetes, docker, GitLab etc.",
        "⚡ Experience in end to end deployment/migration to cloud.",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "",
          style: {
            color: "#FF9900",
          },
        },

        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },

        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },

    {
      title: "Data Engineering",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Experience of managing large volume of financial data",
        "⚡ Experience of Hadoop, Spark and scala ecosystem",
      ],
      softwareSkills: [
        {
          skillName: "Hadoop",
          fontAwesomeClassname: "devicon-plain:hadoop-wordmark",
        },
        {
          skillName: "Scala",
          fontAwesomeClassname: "vscode-icons:file-type-scala",
        },
        {
          skillName: "Spark",
          fontAwesomeClassname: "logos:apache-spark",
          logo_path: "python-open-cv.png",
        },
      ],
    },

    //    {
    //      title: "UI/UX Design",
    //      fileName: "DesignImg",
    //      skills: [
    //        "⚡ Designing highly attractive user interface for mobile and web applications",
    //        "⚡ Customizing logo designs and building logos from scratch",
    //        "⚡ Creating the flow of application functionalities to optimize user experience",
    //      ],
    //      softwareSkills: [
    //        {
    //          skillName: "Adobe XD",
    //          fontAwesomeClassname: "simple-icons:adobexd",
    //          style: {
    //            color: "#FF2BC2",
    //          },
    //        },
    //        {
    //          skillName: "Figma",
    //          fontAwesomeClassname: "simple-icons:figma",
    //          style: {
    //            color: "#F24E1E",
    //          },
    //        },
    //        {
    //          skillName: "Adobe Illustrator",
    //          fontAwesomeClassname: "simple-icons:adobeillustrator",
    //          style: {
    //            color: "#FF7C00",
    //          },
    //        },
    //        {
    //          skillName: "Inkscape",
    //          fontAwesomeClassname: "simple-icons:inkscape",
    //          style: {
    //            color: "#000000",
    //          },
    //        },
    //      ],
    //    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    // {
    //   siteName: "Java",
    //   iconifyClassname: "devicon:java-wordmark",
    //   style: {
    //     color: "#F79F1B",
    //   },
    // },
    // {
    //   siteName: "SpringBoot",
    //   iconifyClassname: "bxl:spring-boot",
    //   style: {
    //     color: "#2EC866",
    //   },
    // },
    // {
    //   siteName: "Microservices",
    //   iconifyClassname: "carbon:microservices-1",
    //   style: {
    //     color: "#5B4638",
    //   },
    // },
    // {
    //   siteName: "Oracle",
    //   iconifyClassname: "devicon:oracle",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Inderprastha Engineering College",
      subtitle: " Bachelor's degree, Computer Science",
      logo_path: "ipec.png",
      alt_name: "Inderprastha Engineering College",
      duration: "2013 - 2017",
      descriptions: [
        "⚡ Bachelore's degree focused in Computer Science.",
        "⚡ Did internship in NICsi.",
        "⚡ Developed few android apps during 3rd and 4th year of college.",
        "⚡ Worked on many robotics projects, won several awards, built a community and delivered huge robotics workshop, was honored with title Mr. Robot.",
      ],
      website_link: "https://www.ipec.org.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Java",
      logo_path: "java.jpg",
      certificate_link:
        "https://www.linkedin.com/in/satishverma110394/details/certifications/",
      color_code: "#FFFFFF",
    },
    {
      title: "Spring Boot & Hibernate",
      logo_path: "spring-hibernate.jpg",
      certificate_link:
        "https://www.udemy.com/certificate/UC-d0325b14-e366-49de-b800-c1bb25021172/",
      color_code: "#FFFFFF",
    },
    {
      title: "Java Unit Testing with Junit & Mockito",
      logo_path: "Mockito.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-ef7e96ef-38b0-4097-8178-f88a54fcfc79/",
      color_code: "#FFFFFF",
    },
    {
      title: "Agile Fundamentals Including Scrum and Kanban",
      logo_path: "agile.png",
      certificate_link:
        "https://udemy-certificate.s3.amazonaws.com/pdf/UC-34fd5b53-fda4-428b-a4f9-7b5d8210927b.pdf",
      color_code: "#FFFFFF",
    },
    {
      title: "The Complete Java Script Course",
      //subtitle: "- Jogesh Muppala",
      logo_path: "javascript.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-ecf7fb6c-194b-47fd-9b87-56f2947e588d/",
      color_code: "#FFFFFF",
    },
    {
      title: "Apache Spark 3 & Big Data Essentials in Scala",
      //  subtitle: "- Qwiklabs",
      logo_path: "apache.png",
      certificate_link: "https://www.udemy.com/certificate/UC-MXJCXETU/",
      color_code: "#FFFFFF",
    },
    {
      title: "The Complete Android8.0 0reo Developer",
      logo_path: "android-8.jpeg",
      certificate_link:
        "https://www.udemy.com/certificate/UC-308a4e0b-0ec7-4117-884d-3185f7b8729c/",
      color_code: "#FFFFFF",
    },
    {
      title: "Python for beginners",
      logo_path: "python.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-079ab1d8-4a45-4c04-bfc1-5dafd632f8d6/",
      color_code: "#FFFFFF",
    },
    {
      title: "Automatic Multiple Face Recognition Using Python",
      logo_path: "python-open-cv.png",
      certificate_link: "https://www.udemy.com/certificate/UC-MXJCXETU/",
      color_code: "#FFFFFF",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work and Skills",
  description:
    "An expert in Financial domain, I have 7 years of experience with Goldman Sachs, Morgan Stanley, Nucleus Software & Agoda (Fintech-Thailand). Skilled in Java, Scala Spring boot & Micro services, web services (SOAP and REST), Hadoop, Spark ,AWS, SQL, PLSQL, Oracle, Sybase, MemSQL Database, BPMN-Flowable, Kubernetes, HTML, CSS, Javascript, Bootstrap, React native, SDLC Highly skilled in Lending domain - First hand experience of developing lending solutions end to end.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Corporate Experience",
      work: true,
      experiences: [
        {
          title: "Senior Software Engineer",
          company: "Agoda",
          company_url: "https://agoda.com",
          logo_path: "agoda.webp",
          duration: "June 2022 ",
          location: "Bangkok, Thailand",
          description:
            "Handling Finance data of Agoda using cutting edge technologies like Hadoop, Spark and Scala.",
          color: "#0879bf",
        },
        {
          title: "Full-stack Developer",
          company: "Goldman Sachs",
          company_url: "https://www.goldmansachs.com/",
          logo_path: "goldman_sachs.png",
          duration: "Nov2021 - July 2022",
          location: "Bangalore Urban, Karnataka, India ",
          description:
            "Worked with Goldman Sachs as a Full Stack Developer on behalf of Wissen Technology.",
          color: "#9b1578",
        },
        {
          title: "Back End Developer",
          company: "Morgan Stanley .",
          company_url: "https://www.linkedin.com/company/morgan-stanley/",
          logo_path: "morgan_stanley.png",
          duration: "Aug 2021 - Nov 2021",
          location: "Bengaluru, Karnataka, India",
          description:
            "Worked with Morgan Stanley as Backend Developer on behalf of Wissen Technology.",
          color: "#fc1f20",
        },
        {
          title: "Software Engineer",
          company: "Nucleus Software .",
          company_url:
            "https://www.linkedin.com/company/nucleus-software-exports-limited/",
          logo_path: "nucleus_soft.jpeg",
          duration: "Dec 2018 - july 2021",
          location: "Noida, Uttar Pradesh, India",
          description:
            "Worked on Customer acquisition and loan management modules as full stack developer",
          color: "#fc1f20",
        },
        {
          title: "Assistant Software Engineer",
          company: "Nucleus Software .",
          company_url:
            "https://www.linkedin.com/company/nucleus-software-exports-limited/",
          logo_path: "nucleus_soft.jpeg",
          duration: "Dec 2017 - Dec 2018",
          location: "Noida, Uttar Pradesh, India",
          color: "#fc1f20",
        },
        {
          title: "Software Engineer Intern",
          company: "Nucleus Software .",
          company_url:
            "https://www.linkedin.com/company/nucleus-software-exports-limited/",
          logo_path: "nucleus_soft.jpeg",
          duration: "Jun 2017 - Dec 2017",
          location: "Noida, Uttar Pradesh, India",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Freelance work",
      work: true,
      experiences: [
        {
          title: "Neero lending app",
          company: "Neero",
          company_url: "https://www.neero.io/customer",
          logo_path: "neero_cm_logo.jpeg",
          location: "Paris, France (Remote)",
          description:
            "Developed whole Loan origination, sanction and EMI management backend from scratch",
          color: "#0879bf",
        },
        {
          title: "E-commerce app",
          company: "Chaudhary Fertilisers",
          company_url:
            "https://play.google.com/store/apps/details?id=com.martian.cf",
          logo_path: "chaudhary_fert.webp",
          location: "India",
          description:
            "Developed whole E-commerce app from scratch with both customer and admin module",
          color: "#0879bf",
        },
      ],
    },
  ],
};

// Projects Page

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "satish.jpeg",
    description:
      "Have something interesting to talk about? or just want to keep in touch, hit me up on LinkedIn, I reply to all messages. Let's build something magical together!",
  },

  blogSection: {
    title: "",
    subtitle: "",
    link: "",
    avatar_image_path: "satish.svg",
  },
  addressSection: {},
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  contactPageData,
};
