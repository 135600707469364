import React from "react";
import "./LoaderLogo.css";

class LogoLoader extends React.Component {
  render() {
    return (
      <svg>
        <text x="50%" y="50%" dy=".15em" text-anchor="middle">
          𝓢𝓪𝓽𝓲𝓼𝓱 𝓥𝓮𝓻𝓶𝓪
        </text>
      </svg>
    );
  }
}

export default LogoLoader;
